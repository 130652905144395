/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import companyLogo from "../assets/logo/logo_normal_navy.png";

const Main: React.FC = () => {
  const { pathname } = useLocation();
  const LIV_URL = `livinginhotel:/${pathname}`;
  const isIos = navigator.userAgent
    .toLowerCase()
    .match(/ipod|ipad|ios|iphone/i);
  const isAndroid = navigator.userAgent.toLowerCase().match(/android/i);

  const redirectApp = () => {
    redirectStore();
  };

  const redirectStore = () => {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        exeDeepLink();
      } else {
        if (isIos) {
          if (window.confirm("스토어로 이동하시겠습니까?")) {
            location.href = "https://itunes.apple.com/app/id1658290368";
          }
        } else if (isAndroid) {
          if (window.confirm("스토어로 이동하시겠습니까?")) {
            location.href =
              "https://play.google.com/store/apps/details?id=com.livinginhotel.app";
          }
        } else {
          location.href = "https://livinginhotel.com/";
        }
      }
    };
    xhr.open("head", LIV_URL);
    xhr.send(null);
  };

  function exeDeepLink() {
    const url = LIV_URL;
    location.href = url;
  }

  useEffect(() => {
    redirectApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <div>
        <div>
          <img src={companyLogo} width={84} alt="Travelmakers Inc. logo" />
        </div>
        <div>
          <div className="button" onClick={redirectApp}>
            호텔에삶 바로가기
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > div {
    width: 195px;
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;
    & > div {
      width: 100%;
      text-align: center;
    }
    & > div > .button {
      background-color: rgb(48, 55, 63);
      height: 100%;
      cursor: pointer;
      transform: none;
      opacity: 1;
      border-radius: 100px;
      padding: 10px 20px 10px 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      font-family: Inter, sans-serif;
    }
  }
`;

export default Main;
